var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{ref:"ruleWrap",staticClass:"rule-wrap"},[_c('div',{ref:"rule",staticClass:"rule"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('img',{staticClass:"guchi-rule",attrs:{"src":"images/home/guchi_rule.png","alt":"グチルール"}})])]),_c('router-link',{ref:"buttonLink",staticClass:"button-link",attrs:{"to":"/guchi-burst"}},[_c('div',{staticClass:"microphone"},[_c('img',{attrs:{"src":"images/microphone.svg"}})]),_c('img',{staticClass:"btn_start",attrs:{"src":"images/home/btn_start.png"}})]),_c('div',{staticClass:"sns-buttons"},[_c('a',{attrs:{"href":`https://twitter.com/share?url=${_vm.currentUrl}&text=${_vm.twetterText}&hashtags=${_vm.twetterTag}`,"target":"_blank"}},[_c('img',{attrs:{"src":"images/sns_buttons/button_tw.png"}})]),_c('a',{attrs:{"href":`http://www.facebook.com/share.php?u=${_vm.currentUrl}`,"target":"_blank"}},[_c('img',{attrs:{"src":"images/sns_buttons/button_fb.png"}})]),_c('a',{attrs:{"href":`https://social-plugins.line.me/lineit/share?url=${_vm.currentUrl}`,"target":"_blank"}},[_c('img',{attrs:{"src":"images/sns_buttons/button_line.png"}})])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h1',[_c('img',{attrs:{"src":"images/logo.svg"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item text1"},[_c('img',{staticClass:"item-bg",attrs:{"src":"images/home/bg1.png"}}),_c('img',{staticClass:"item-no no1",attrs:{"src":"images/home/no1.png"}}),_c('p',[_vm._v("グチを言っちゃいなよ！")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item text2"},[_c('img',{staticClass:"item-bg",attrs:{"src":"images/home/bg2.png"}}),_c('img',{staticClass:"item-no no2",attrs:{"src":"images/home/no2.png"}}),_c('p',[_vm._v("15秒間精一杯グチって見てよ。")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item text3"},[_c('img',{staticClass:"item-bg",attrs:{"src":"images/home/bg3.png"}}),_c('img',{staticClass:"item-no no3",attrs:{"src":"images/home/no3.png"}}),_c('p',[_vm._v("YOUの言葉をテキストにするけど、"),_c('br'),_vm._v("まちがってたらごめんよ！")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item text4"},[_c('img',{staticClass:"item-bg",attrs:{"src":"images/home/bg4.png"}}),_c('img',{staticClass:"item-no no4",attrs:{"src":"images/home/no4.png"}}),_c('p',[_vm._v("ボタンを押して、"),_c('br'),_vm._v("グチを爆破してみてー!?")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item text5"},[_c('img',{staticClass:"item-bg",attrs:{"src":"images/home/bg5.png"}}),_c('img',{staticClass:"item-no no5",attrs:{"src":"images/home/no5.png"}}),_c('p',[_vm._v("意外とスッキリするかもよ!")])])
}]

export { render, staticRenderFns }