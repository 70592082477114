
import Vue from "vue";

export default Vue.extend({
  name: "Home",
  computed: {
    currentUrl(): string {
      return encodeURI(location.href);
    },
    twetterText(): string {
      return "グチバースト";
    },
    twetterTag(): string {
      return "グチバースト";
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
    console.log(process.env);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      const rule = this.$refs.rule;
      const ruleWrap = this.$refs.ruleWrap;
      const buttonLink = document.querySelector(".button-link");
      if (
        rule instanceof HTMLElement &&
        buttonLink instanceof HTMLElement &&
        ruleWrap instanceof HTMLElement
      ) {
        if (window.innerWidth >= 801) {
          const scH =
            window.innerHeight - 280 < 520
              ? (window.innerHeight - 280) / 520
              : 1;
          const scW =
            window.innerWidth < 1050 + 250
              ? window.innerWidth / (1050 + 250)
              : 1;
          const sc = scH < 0.6 || scW < 0.6 ? 0.6 : scH < scW ? scH : scW;
          rule.style.transform = `scale(${sc})`;
          buttonLink.style.marginTop = `${sc * 520}px`;
          ruleWrap.style.height = ``;
          if (window.innerHeight < 860) {
            const diff =
              window.innerHeight > 830 ? window.innerHeight - 830 : 30;
            ruleWrap.style.marginTop = `-${diff}px`;
          } else {
            ruleWrap.style.marginTop = ``;
          }
        } else {
          const sc = window.innerWidth < 635 ? window.innerWidth / 635 : 1;
          rule.style.transform = `scale(${sc})`;
          buttonLink.style.marginTop = `60px`;
          ruleWrap.style.height = `${rule.clientHeight * sc}px`;
          ruleWrap.style.marginTop = ``;
        }
      }
      this.$refs.rule;
    },
  },
});
