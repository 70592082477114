
import Vue from "vue";
import { Recorder } from "@/lib/recorder";
import { Result } from "@/js/result";
import ButtonAPIKeyGet from "../components/ButtonAPIKeyGet.vue";
//import ButtonAPIKeyGetLocal from "../components/ButtonAPIKeyGetLocal.vue";
import RecommendedEnvironment from "../components/RecommendedEnvironment.vue";
import Note from "../components/Note.vue";
import Timer from "../components/Timer.vue";
import SoundControl from "../components/SoundControl.vue";
import ButtonExplosion from "../components/ButtonExplosion.vue";
import Animation from "../components/Animation.vue";
import UnderConstruction from "@/components/UnderConstruction.vue";
import Fukidasi from "@/components/Fukidasi.vue";
import Pelican from "@/components/Pelican.vue";
import ResultText from "@/components/ResultText.vue";
export default Vue.extend({
  name: "WRP",
  data(): {
    isShowRecEnv: boolean;
    sound: boolean;
    explosion: boolean;
    isUnderConstruction: boolean;
    isStartedAnimation: boolean;
    isShowResultText: boolean;
    isAnimationEnd: boolean;

    recording: boolean;
    recorded: boolean;

    isConnecting: boolean;
    serverURL: string;
    socket: WebSocket | undefined;
    grammarFileNames: string;
    authorizedKey: string;
    resultText: string;
    resultTextTweet: string;
    recorder: Recorder | undefined;
    startTime: number;
    timeLeft: number;
    timerID: number;
    convertingText: string;

    fontColor: number;
    fontSize: number;
    fontWeight: number;
  } {
    return {
      isShowRecEnv: false,
      sound: true,
      explosion: false,
      isUnderConstruction: false,
      isStartedAnimation: false,
      isShowResultText: true,
      isAnimationEnd: false,

      recording: false,
      recorded: false,

      isConnecting: false,
      serverURL: "wss://acp-api.amivoice.com/v1/",
      socket: undefined,
      grammarFileNames: "-a-general",
      authorizedKey: "",
      resultText: "",
      resultTextTweet: "",
      recorder: undefined,
      startTime: 0,
      timeLeft: 0,
      timerID: -1,
      convertingText: "",
      fontColor: Math.round(Math.random() * 6) + 1,
      fontSize: Math.round(Math.random()) + 1,
      fontWeight: Math.round(Math.random()) + 1,
    };
  },
  components: {
    ButtonAPIKeyGet,
    //ButtonAPIKeyGetLocal,
    ButtonExplosion,
    SoundControl,
    Animation,
    RecommendedEnvironment,
    Note,
    Timer,
    UnderConstruction,
    Fukidasi,
    Pelican,
    ResultText,
  },
  computed: {
    displayTimer(): string {
      return this.timeLeft > 0 ? `${this.timeLeft}` : "0";
    },
    fukidasi(): number {
      if (!this.recorded && !this.recording) return 1;
      else if (this.recording) return 2;
      else if (this.recorded && !this.isStartedAnimation) return 3;
      return 0;
    },
    twitterUrl(): string {
      const twetterTag = "グチバースト";
      const currentUrl = `${location.protocol}//${location.host}`;
      return `https://twitter.com/share?url=${currentUrl}&text=${this.resultTextTweet}&hashtags=${twetterTag}`;
    },
    isDebugMode(): boolean {
      return (
        process.env.NODE_ENV !== "production" &&
        process.env.NODE_ENV !== "development"
      );
    },
  },
  async mounted() {
    console.log(process.env);
    await this.checkKey();
    this.recorder = new Recorder();
    // 録音ライブラリのプロパティの設定
    this.recorder.isDownSampling = true;
    // 録音の開始処理が完了した時に呼び出されます。
    this.recorder.resumeEnded = () => {
      console.log("this.recorder.resumeEnded");
    };
    // 録音の開始処理が失敗した時または録音の停止処理が完了した時に呼び出されます。
    this.recorder.pauseEnded = (reason) => {
      console.log("this.recorder.pauseEnded", reason);
    };
    // 音声データが録音された時に呼び出されます。
    this.recorder.recorded = (data, offset, length) => {
      if (this.isConnecting) {
        if (offset === 1 && data.length === length + 1) {
          data[0] = 0x70; // 'p'
          if (this.socket) this.socket.send(data);
        } else {
          var outData = new Uint8Array(length + 1);
          outData[0] = 0x70; // 'p'
          for (var i = 0; i < length; i++) {
            outData[1 + i] = data[offset + i];
          }
          if (this.socket) this.socket.send(outData);
        }
      }
    };
  },
  methods: {
    /**
     * ダミーテキスト挿入
     */
    onClickDeBugDummyText(): void {
      if (this.resultText) {
        this.resultText = "";
      } else {
        this.resultText += this.createRandomStyleTextHTML("あいうえお", true);
        this.resultText += this.createRandomStyleTextHTML("お", true);
        this.resultText += this.createRandomStyleTextHTML("かきくけこ", true);
        this.resultText += this.createRandomStyleTextHTML("さしすせそ", true);
        this.resultText += this.createRandomStyleTextHTML("え", true);
        this.resultText += this.createRandomStyleTextHTML("たちつてと", true);
        this.resultText += this.createRandomStyleTextHTML("う", true);
        this.resultText += this.createRandomStyleTextHTML("なにぬねの", true);
        this.resultText += this.createRandomStyleTextHTML("はひふへほ", true);
        this.resultText += this.createRandomStyleTextHTML("い", true);
        this.resultText += this.createRandomStyleTextHTML("まみむめも", true);
        this.resultText += this.createRandomStyleTextHTML("あ", true);
        this.resultText += this.createRandomStyleTextHTML("あいうえお", true);
        this.resultText += this.createRandomStyleTextHTML("お", true);
        this.resultText += this.createRandomStyleTextHTML("かきくけこ", true);
        this.resultText += this.createRandomStyleTextHTML("さしすせそ", true);
        this.resultText += this.createRandomStyleTextHTML("え", true);
        this.resultText += this.createRandomStyleTextHTML("たちつてと", true);
        this.resultText += this.createRandomStyleTextHTML("う", true);
        this.resultText += this.createRandomStyleTextHTML("なにぬねの", true);
        this.resultText += this.createRandomStyleTextHTML("はひふへほ", true);
        this.resultText += this.createRandomStyleTextHTML("い", true);
        this.resultText += this.createRandomStyleTextHTML("まみむめも", true);
        this.resultText += this.createRandomStyleTextHTML("あ", true);
      }
    },
    /**
     * 録音中表示テスト
     */
    onClickDeBugRecording(): void {
      this.recorded = false;
      this.isStartedAnimation = false;
      if (this.recording) {
        this.recording = false;
        this.authorizedKey = "";
      } else {
        this.recording = true;
        this.authorizedKey = "dummy";
      }
    },
    /**
     * 録音終了表示テスト
     */
    onClickDeBugRecorded(): void {
      this.recording = false;
      if (this.recorded) {
        this.recorded = false;
        this.isStartedAnimation = false;
      } else {
        this.recorded = true;
        this.isStartedAnimation = false;
      }
    },
    /**
     * アニメーションテスト
     */
    onClickDeBugAnimation(): void {
      this.isStartedAnimation = !this.isStartedAnimation;
      if (this.isStartedAnimation) {
        this.recorded = true;
        this.explosion = true;
      } else {
        this.recorded = false;
        this.explosion = false;
        this.resultText = "";
        this.resultTextTweet = "";
        this.isAnimationEnd = false;
      }
      this.isShowResultText = true;
    },

    /**
     * リトライボタン
     */
    onClickRetry(): void {
      this.recorded = false;
      this.resultText = "";
      this.resultTextTweet = "";
    },
    /**
     * 爆破ボタン
     */
    onClickExplosion(): void {
      this.explosion = true;
      setTimeout(() => {
        this.isStartedAnimation = true;
      }, 500);
    },
    /**
     * リセット
     */
    reset(): void {
      if (this.isConnecting) this.sendLogStamp("e");
      this.isConnecting = false;
      this.recording = false;
      this.authorizedKey = "";
      clearInterval(this.timerID);
      if (this.socket) this.socket.close();
      if (this.recorder && this.recorder.isActive()) {
        this.recorder.pause();
      }
    },
    /**
     * 認証Key取得完了
     */
    onAuthorized(key: string): void {
      this.authorizedKey = key;
      this.onStartRecording();
    },
    /**
     * タイマー処理
     */
    intervalAction(): void {
      const elapsedTime = Date.now() - this.startTime;
      this.timeLeft = Math.ceil((15000 - elapsedTime) / 1000);
      if (this.timeLeft <= 0) {
        this.onStopRecording();
      }
    },
    /**
     * 録音開始
     */
    async onStartRecording(): Promise<void> {
      if (this.recorder && !this.recorder.isActive()) {
        this.recorder.resume(); //録音開始
        //認証が入る可能性があるのでRecorderの起動を待って通信を開始する
        let cont = 60;
        while (!this.recorder.isActive() && cont--) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          console.log("count:", cont);
        }
        if (cont <= 0) {
          alert("録音の開始に失敗しました。");
          this.authorizedKey = "";
          return;
        }
        this.recording = true;
        this.resultText = "";
        this.resultTextTweet = "";
        this.startTime = Date.now();
        this.timerID = setInterval(this.intervalAction, 100);
        this.connect();
      }
    },
    /**
     * 録音停止
     */
    onStopRecording(): void {
      this.recording = false;
      this.recorded = true;
      clearInterval(this.timerID);
      //*
      if (this.recorder && this.recorder.isActive()) {
        this.recorder.pause();
      }
      this.disconnect();
      //*/
    },

    /**
     * 終了コマンドを送信
     */
    disconnect(): void {
      if (this.isConnecting) this.sendLogStamp("e");
      this.isConnecting = false;
      const command = "e";
      if (this.socket) this.socket.send(command);
      console.log(`<- ${command}`);
    },

    /**
     * WebSocket のオープン
     */
    connect(): void {
      if (!window.WebSocket) {
        console.error(
          `ERROR: can't start feeding data to HTTP server (Unsupported WebSocket class)`
        );
        return;
      }
      try {
        this.socket = new WebSocket(this.serverURL);
      } catch (e) {
        console.error(e);
        return;
      }
      this.socket.onopen = this.onSocketOpen;
      this.socket.onclose = this.onSocketClose;
      this.socket.onmessage = this.onSocketReceive;
    },

    /**
     * 接続開始時処理
     */
    onSocketOpen(/*event: Event*/): void {
      let command = "s MSB16K";
      if (this.grammarFileNames) {
        command += " " + this.grammarFileNames;
        if (
          this.grammarFileNames.indexOf("\x01") != -1 &&
          !this.grammarFileNames.endsWith("\x01")
        ) {
          command += "\x01";
        }
      } else {
        command += " \x01";
      }
      if (this.authorizedKey) {
        command += ` authorization=${this.authorizedKey}`;
      }
      if (this.socket) this.socket.send(command);
      //console.log("<- " + command);
    },

    /**
     * 接続終了時処理
     */
    onSocketClose(/*event: CloseEvent*/): void {
      console.log(`socket.onclose`);
    },

    /**
     * メッセージ受信時処理
     */
    onSocketReceive(event: MessageEvent): void {
      if (typeof event.data !== "string") {
        console.error("type is not string");
        return;
      }
      const tag = event.data[0];
      const body = event.data.substring(2);
      //console.log("-> ", tag, body);
      if (tag === "s") {
        //送信開始処理
        if (body) {
          console.error(
            `ERROR: can't start feeding data to WebSocket server (${body})`
          );
          this.reset();
        } else {
          this.isConnecting = true;
          this.sendLogStamp("s");
        }
      } else if (tag === "p") {
        //音声パケット
        if (body) {
          console.error(`ERROR: can't feed data to WebSocket server (${body})`);
          this.reset();
        }
      } else if (tag === "e") {
        //送信終了処理
        if (body) {
          console.error(`ERROR: can't feed data to WebSocket server (${body})`);
        }
        this.reset();
      } else if (tag === "S") {
        // 発話区間の始端が検出された時に呼び出されます。
      } else if (tag === "E") {
        // 発話区間の終端が検出された時に呼び出されます。
      } else if (tag === "C") {
        // 認識処理が開始された時に呼び出されます。
      } else if (tag === "U") {
        // 認識処理中に呼び出されます。
        const result = Result.parse(body);
        this.convertingText = result.text
          ? this.createRandomStyleTextHTML(this.sanitize(result.text))
          : "";
      } else if (tag === "A") {
        // 認識処理が確定した時に呼び出されます。
        const result = Result.parse(body);
        this.convertingText = "";
        this.resultText += result.text
          ? this.createRandomStyleTextHTML(this.sanitize(result.text), true)
          : ",";
        this.resultTextTweet += result.text ? this.sanitize(result.text) : ",";
      } else if (tag === "R") {
        // 認識処理が確定した時に呼び出されます。
      } else if (tag === "Q") {
        // 各種イベントが通知された時に呼び出されます。
      } else if (tag === "G") {
        // 各種イベントが通知された時に呼び出されます。
      }
    },

    createRandomStyleTextHTML(text: string, update = false) {
      const ret = `<span class="c${this.fontColor} s${this.fontSize} b${this.fontWeight}" >${text}</span>`;
      if (update) {
        this.fontColor = Math.round(Math.random() * 6) + 1;
        this.fontSize = Math.round(Math.random()) + 1;
        this.fontWeight = Math.round(Math.random()) + 1;
      }
      return ret;
    },

    /**
     * 送信開始サーバログスタンプ
     */
    async sendLogStamp(typeStartOrEnd: "s" | "e"): Promise<void> {
      try {
        const url = `./manage/f.php?p=${this.authorizedKey}&f=${typeStartOrEnd}`;
        const res = await fetch(url);
        const json = await res.json();
        if (json.status === "error") throw Error("sendLogStamp:API error");
      } catch (e) {
        this.isUnderConstruction = true;
      }
    },

    /**
     * 簡易サニタイザー
     */
    sanitize(s: string) {
      return s
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/'/g, "&apos;")
        .replace(/"/g, "&quot;");
    },

    /**
     * サービス認証キー文字列の発行(初回工事中チェックよう)
     */
    async checkKey(): Promise<void> {
      try {
        const url = "./manage/a.php";
        const res = await fetch(url);
        const json = await res.json();
        if (json.status === "error") throw Error("API error");
      } catch (e) {
        this.isUnderConstruction = true;
      }
    },
  },
});
