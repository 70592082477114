
import Vue from "vue";

export default Vue.extend({
  name: "Note",
  methods: {
    onClickNote(): void {
      this.$emit("onClickNote");
    },
  },
});
