
import Vue from "vue";

export default Vue.extend({
  name: "ButtonTwitter",
  props: {
    href: {
      type: String,
      required: true,
    },
  },
});
