
import Vue from "vue";

export default Vue.extend({
  name: "SoundControl",
  props: {
    sound: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    onSoundControl(): void {
      this.$emit("onSoundControl");
    },
  },
});
