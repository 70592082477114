
import Vue from "vue";

export default Vue.extend({
  name: "ButtonRetry",
  methods: {
    onClickRetry(): void {
      this.$emit("onClickRetry");
    },
  },
});
