
import Vue from "vue";
import buttonSe from "@/assets/se/button.mp3";

export default Vue.extend({
  name: "ButtonExplosion",
  data(): {
    audio: HTMLAudioElement;
  } {
    return {
      audio: new Audio(buttonSe),
    };
  },
  props: {
    isDown: {
      type: Boolean,
      required: false,
      default: false,
    },
    sound: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    onClick(): void {
      this.audio.currentTime = 0;
      if (this.sound) this.audio.play();
      this.$emit("onClick");
    },
  },
});
