
import Vue from "vue";
import ButtonRetry from "../components/ButtonRetry.vue";
import ButtonTwitter from "../components/ButtonTwitter.vue";

export default Vue.extend({
  name: "SoundControl",
  props: {
    isShowRetryButton: {
      type: Boolean,
      required: true,
    },
    convertingText: {
      type: String,
      required: false,
      default: "",
    },
    resultText: {
      type: String,
      required: false,
      default: "",
    },
    tweetUrl: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {
    ButtonRetry,
    ButtonTwitter,
  },
  methods: {
    onClickRetry(): void {
      this.$emit("onClickRetry");
    },
  },
});
