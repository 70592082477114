
import Vue from "vue";

export default Vue.extend({
  name: "Fukidasi",
  props: {
    fukidasi: {
      type: Number,
      required: true,
      default: 1,
    },
  },
});
