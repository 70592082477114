
import Vue from "vue";

export default Vue.extend({
  name: "Timer",
  props: {
    time: {
      type: String,
      required: false,
      default: "0",
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
