
import Vue from "vue";

export default Vue.extend({
  methods: {
    /**
     * サービス認証キー文字列の発行
     */
    async getKey(): Promise<void> {
      try {
        const url = "./onetime/x.php";
        const res = await fetch(url);
        const json = await res.json();
        if (json.status === "success") {
          this.$emit("authorized", json.message);
        } else {
          console.error(json);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
});
