
import Vue from "vue";
import fuseSe from "@/assets/se/fuse.mp3";
import fallingSe from "@/assets/se/falling.mp3";
import explosionSe from "@/assets/se/explosion.mp3";
import landingSe from "@/assets/se/landing.mp3";
import gjSe from "@/assets/se/gj.mp3";

export default Vue.extend({
  name: "Timer",
  data(): {
    mount: boolean;
    start: number;
    time: number;
    step: number;
    loadedSECount: number;
    audioFuse: HTMLAudioElement;
    audioFalling: HTMLAudioElement;
    audioExplosion: HTMLAudioElement;
    audioLanding: HTMLAudioElement;
    audioGj: HTMLAudioElement;
  } {
    return {
      mount: false,
      start: 0,
      time: 0,
      step: 0,
      loadedSECount: 0,
      audioFuse: new Audio(fuseSe),
      audioFalling: new Audio(fallingSe),
      audioExplosion: new Audio(explosionSe),
      audioLanding: new Audio(landingSe),
      audioGj: new Audio(gjSe),
    };
  },
  props: {
    sound: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    frames(): number[] {
      return [
        1900, //bomb1
        1500, //bomb2
        2000, //bomb3
        100, //explosion1
        100, //explosion2
        100, //explosion3
        100, //explosion4
        100, //explosion5
        200, //explosion6
        200, //explosion7
        200, //explosion8
        2000,
        1000,
        1000,
      ];
    },
  },
  mounted() {
    this.mount = true;
    this.start = Date.now();
    this.audioFalling.load();
    this.audioLanding.load();
    this.audioFuse.load();
    this.audioExplosion.load();
    this.audioFalling.addEventListener("loadedmetadata", this.loadedSE);
    this.audioLanding.addEventListener("loadedmetadata", this.loadedSE);
    this.audioFuse.addEventListener("loadedmetadata", this.loadedSE);
    this.audioExplosion.addEventListener("loadedmetadata", this.loadedSE);
    this.audioGj.addEventListener("loadedmetadata", this.loadedSE);
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  beforeDestroy() {
    this.audioFalling.removeEventListener("loadedmetadata", this.loadedSE);
    this.audioLanding.removeEventListener("loadedmetadata", this.loadedSE);
    this.audioFuse.removeEventListener("loadedmetadata", this.loadedSE);
    this.audioExplosion.removeEventListener("loadedmetadata", this.loadedSE);
    this.audioGj.removeEventListener("loadedmetadata", this.loadedSE);
    window.removeEventListener("resize", this.resize);
    this.mount = false;
  },
  watch: {
    step(to, from) {
      console.log(to, from);
      if (this.sound) {
        if (to === 1) {
          this.audioLanding.currentTime = 0.3;
          this.audioLanding.play();
        } else if (to === 2) {
          this.audioFuse.play();
        } else if (to === 3) {
          this.audioExplosion.play();
        } else if (to === 12) {
          this.audioGj.play();
        }
      }
      if (to === 13) {
        this.$emit("onAnimationEnd");
      }
    },
  },
  methods: {
    resize() {
      const bombWrap = this.$refs.bombWrap;
      const expWrap = this.$refs.expWrap;
      const gjWrap = this.$refs.gjWrap;

      if (
        bombWrap instanceof HTMLElement &&
        expWrap instanceof HTMLElement &&
        gjWrap instanceof HTMLElement
      ) {
        const scH = window.innerHeight < 1000 ? window.innerHeight / 1000 : 1;
        const scW = window.innerWidth < 500 ? 0.6 : 1;
        const sc = scH < scW ? scH : scW;
        bombWrap.style.transform = `scale(${sc})`;
        expWrap.style.transform = `scale(${sc})`;
        if (window.innerWidth >= 801) gjWrap.style.transform = `scale(${sc})`;
      }
    },
    loadedSE(): void {
      this.loadedSECount++;
      console.log(this.loadedSECount);
      if (this.loadedSECount === 5) {
        this.audioFalling.playbackRate = 3;
        this.audioFuse.playbackRate = 2;

        console.log(this.audioFalling.duration); // 総時間の取得
        console.log("audioFalling", this.audioFalling.duration);
        console.log("audioLanding", this.audioLanding.duration);
        console.log("audioFuse", this.audioFuse.duration);
        console.log("audioExplosion", this.audioExplosion.duration);
        this.animation();
        if (this.sound) this.audioFalling.play();
      }
    },
    /**
     * アニメーション
     */
    animation(): void {
      this.time = Date.now() - this.start;
      let index = 0;
      let sum = 0;
      while (this.frames[index] && this.time > sum) {
        sum += this.frames[index];
        if (this.time <= sum) this.step = index;
        index++;
      }
      if (this.step === 3) this.$emit("onBomb");
      //console.log(this.time);
      if (this.mount && this.time < 30000)
        window.requestAnimationFrame(this.animation);
    },
  },
});
