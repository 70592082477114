export class Recorder {
  public isDownSampling = false;

  private state = -1;
  private audioContext: AudioContext | undefined;
  private audioProcessor: ScriptProcessorNode | undefined;
  private waveData: ArrayBufferLike[] = [];
  private waveDataBytes = 0;
  private audioStream: MediaStream | undefined;
  private audioProvider: MediaStreamAudioSourceNode | undefined;
  private pcmData = new Uint8Array(0);
  private temporaryAudioDataSamples = 0;
  private temporaryAudioData: Float32Array | undefined = undefined;
  private coefData: Float32Array | undefined = undefined;
  private audioDecimatationFactor = 0;
  private audioSamplesPerSec = 0;
  private waveFile: Blob | undefined;

  public recorded:
    | ((data: Uint8Array, offset: number, length: number) => void)
    | undefined = undefined;

  public resumeStarted: (() => void) | undefined = undefined;

  public resumeEnded: ((audioSamplesPerSec?: number) => void) | undefined =
    undefined;

  public pauseStarted: (() => void) | undefined = undefined;

  public pauseEnded:
    | ((
        reason: { code: number; message: string },
        waveFile: Blob | undefined
      ) => void)
    | undefined = undefined;

  public initialize = (): void => {
    this.audioContext = new AudioContext();
    this.audioProcessor = this.audioContext.createScriptProcessor(0, 1, 1);
    console.log(this.audioProcessor);

    if (this.audioContext.sampleRate === 48000) {
      this.audioSamplesPerSec = 16000;
      this.audioDecimatationFactor = 3;
    } else if (this.audioContext.sampleRate === 44100) {
      this.audioSamplesPerSec = 22050;
      this.audioDecimatationFactor = 2;
    } else if (this.audioContext.sampleRate === 22050) {
      this.audioSamplesPerSec = 22050;
      this.audioDecimatationFactor = 1;
    } else if (this.audioContext.sampleRate === 16000) {
      this.audioSamplesPerSec = 16000;
      this.audioDecimatationFactor = 1;
    } else {
      this.audioSamplesPerSec = 0;
      this.audioDecimatationFactor = 0;
    }
    if (this.audioDecimatationFactor > 1) {
      this.temporaryAudioData = new Float32Array(
        20 +
          ((this.audioProcessor.bufferSize / this.audioDecimatationFactor) |
            0) *
            this.audioDecimatationFactor
      );
      this.temporaryAudioDataSamples = 0;
      this.coefData = new Float32Array(10 + 1 + 10);
      if (this.audioDecimatationFactor == 3) {
        this.coefData[0] = -1.9186907e-2;
        this.coefData[1] = 1.2144312e-2;
        this.coefData[2] = 3.8677038e-2;
        this.coefData[3] = 3.1580867e-2;
        this.coefData[4] = -1.2342449e-2;
        this.coefData[5] = -6.0144741e-2;
        this.coefData[6] = -6.17571e-2;
        this.coefData[7] = 1.2462522e-2;
        this.coefData[8] = 1.4362448e-1;
        this.coefData[9] = 2.6923548e-1;
        this.coefData[10] = 3.209038e-1;
        this.coefData[11] = 2.6923548e-1;
        this.coefData[12] = 1.4362448e-1;
        this.coefData[13] = 1.2462522e-2;
        this.coefData[14] = -6.17571e-2;
        this.coefData[15] = -6.0144741e-2;
        this.coefData[16] = -1.2342449e-2;
        this.coefData[17] = 3.1580867e-2;
        this.coefData[18] = 3.8677038e-2;
        this.coefData[19] = 1.2144312e-2;
        this.coefData[20] = -1.9186907e-2;
      } else {
        this.coefData[0] = 6.91278819431317970157e-6;
        this.coefData[1] = 3.50501872599124908447e-2;
        this.coefData[2] = -6.93948777552577666938e-6;
        this.coefData[3] = -4.52254377305507659912e-2;
        this.coefData[4] = 6.96016786605468951166e-6;
        this.coefData[5] = 6.34850487112998962402e-2;
        this.coefData[6] = -6.97495897838962264359e-6;
        this.coefData[7] = -1.05997055768966674805e-1;
        this.coefData[8] = 6.9839420575590338558e-6;
        this.coefData[9] = 3.18274468183517456055e-1;
        this.coefData[10] = 4.99993026256561279297e-1;
        this.coefData[11] = 3.18274468183517456055e-1;
        this.coefData[12] = 6.9839420575590338558e-6;
        this.coefData[13] = -1.05997055768966674805e-1;
        this.coefData[14] = -6.97495897838962264359e-6;
        this.coefData[15] = 6.34850487112998962402e-2;
        this.coefData[16] = 6.96016786605468951166e-6;
        this.coefData[17] = -4.52254377305507659912e-2;
        this.coefData[18] = -6.93948777552577666938e-6;
        this.coefData[19] = 3.50501872599124908447e-2;
        this.coefData[20] = 6.91278819431317970157e-6;
      }
    }
    this.pcmData = new Uint8Array(
      1 +
        ((this.audioProcessor.bufferSize / this.audioDecimatationFactor) | 0) *
          2
    );
    this.state = 0;
  };

  private stateSetEnd = (): void => {
    if (this.state === 3) {
      this.state = 4;
      this.stopTracks();
      this.audioStream = undefined;
      if (this.audioProvider) this.audioProvider.disconnect();
      this.audioProvider = undefined;
      if (this.audioProcessor) this.audioProcessor.disconnect();
      console.log("INFO: stopped recording");
    }
  };

  private onAudioProcess = (event: AudioProcessingEvent) => {
    const audioData = event.inputBuffer.getChannelData(0);
    const pcmData = new Uint8Array(audioData.length * 2);
    let pcmDataIndex = 0;
    for (
      let audioDataIndex = 0;
      audioDataIndex < audioData.length;
      audioDataIndex++
    ) {
      let pcm = (audioData[audioDataIndex] * 32768) | 0; // 小数 (0.0～1.0) を 整数 (-32768～32767) に変換...
      if (pcm > 32767) {
        pcm = 32767;
      } else if (pcm < -32768) {
        pcm = -32768;
      }
      pcmData[pcmDataIndex++] = pcm & 0xff;
      pcmData[pcmDataIndex++] = (pcm >> 8) & 0xff;
    }
    this.waveData.push(pcmData.buffer);
    this.waveDataBytes += pcmData.buffer.byteLength;
    this.stateSetEnd();
  };

  private onRecorded = (event: AudioProcessingEvent) => {
    const audioData = event.inputBuffer.getChannelData(0);
    let pcmDataIndex = 1;
    for (
      let audioDataIndex = 0;
      audioDataIndex < audioData.length;
      audioDataIndex++
    ) {
      let pcm = (audioData[audioDataIndex] * 32768) | 0; // 小数 (0.0～1.0) を 整数 (-32768～32767) に変換...
      if (pcm > 32767) {
        pcm = 32767;
      } else if (pcm < -32768) {
        pcm = -32768;
      }
      this.pcmData[pcmDataIndex++] = (pcm >> 8) & 0xff;
      this.pcmData[pcmDataIndex++] = pcm & 0xff;
    }
    if (this.recorded) this.recorded(this.pcmData, 1, pcmDataIndex - 1);
    this.stateSetEnd();
  };
  private downSampling = (event: AudioProcessingEvent) => {
    if (this.state === 0) return; // for Safari
    const audioData = event.inputBuffer.getChannelData(0);
    let audioDataIndex = 0;
    if (!this.temporaryAudioData) return;
    if (!this.coefData) return;
    while (this.temporaryAudioDataSamples < this.temporaryAudioData.length) {
      this.temporaryAudioData[this.temporaryAudioDataSamples++] =
        audioData[audioDataIndex++];
    }
    while (this.temporaryAudioDataSamples == this.temporaryAudioData.length) {
      const pcmData = new Uint8Array(
        ((audioData.length / this.audioDecimatationFactor) | 0) * 2
      );
      let pcmDataIndex = 0;
      for (
        let temporaryAudioDataIndex = this.audioDecimatationFactor - 1;
        temporaryAudioDataIndex + 20 < this.temporaryAudioData.length;
        temporaryAudioDataIndex += this.audioDecimatationFactor
      ) {
        let pcm_float = 0.0;
        for (let i = 0; i <= 20; i++) {
          pcm_float +=
            this.temporaryAudioData[temporaryAudioDataIndex + i] *
            this.coefData[i];
        }
        let pcm = (pcm_float * 32768) | 0; // 小数 (0.0～1.0) を 整数 (-32768～32767) に変換...
        if (pcm > 32767) {
          pcm = 32767;
        } else if (pcm < -32768) {
          pcm = -32768;
        }
        pcmData[pcmDataIndex++] = pcm & 0xff;
        pcmData[pcmDataIndex++] = (pcm >> 8) & 0xff;
      }
      this.waveData.push(pcmData.buffer);
      this.waveDataBytes += pcmData.buffer.byteLength;
      this.temporaryAudioDataSamples = 0;
      let temporaryAudioDataIndex = this.temporaryAudioData.length - 20;
      while (temporaryAudioDataIndex < this.temporaryAudioData.length) {
        this.temporaryAudioData[this.temporaryAudioDataSamples++] =
          this.temporaryAudioData[temporaryAudioDataIndex++];
      }
      while (audioDataIndex < audioData.length) {
        this.temporaryAudioData[this.temporaryAudioDataSamples++] =
          audioData[audioDataIndex++];
      }
    }
    this.stateSetEnd();
  };
  private downSamplingRecorded = (event: AudioProcessingEvent) => {
    if (this.state === 0) return; // for Safari
    const audioData = event.inputBuffer.getChannelData(0);
    let audioDataIndex = 0;
    while (
      this.temporaryAudioData &&
      this.temporaryAudioDataSamples < this.temporaryAudioData.length
    ) {
      this.temporaryAudioData[this.temporaryAudioDataSamples++] =
        audioData[audioDataIndex++];
    }
    while (
      this.temporaryAudioData &&
      this.temporaryAudioDataSamples == this.temporaryAudioData.length
    ) {
      let pcmDataIndex = 1;
      for (
        let temporaryAudioDataIndex = this.audioDecimatationFactor - 1;
        temporaryAudioDataIndex + 20 < this.temporaryAudioData.length;
        temporaryAudioDataIndex += this.audioDecimatationFactor
      ) {
        let pcm_float = 0.0;
        if (this.coefData) {
          for (let i = 0; i <= 20; i++) {
            pcm_float +=
              this.temporaryAudioData[temporaryAudioDataIndex + i] *
              this.coefData[i];
          }
        }
        let pcm = (pcm_float * 32768) | 0; // 小数 (0.0～1.0) を 整数 (-32768～32767) に変換...
        if (pcm > 32767) {
          pcm = 32767;
        } else if (pcm < -32768) {
          pcm = -32768;
        }
        this.pcmData[pcmDataIndex++] = (pcm >> 8) & 0xff;
        this.pcmData[pcmDataIndex++] = pcm & 0xff;
      }
      if (this.recorded) this.recorded(this.pcmData, 1, pcmDataIndex - 1);
      this.temporaryAudioDataSamples = 0;
      let temporaryAudioDataIndex = this.temporaryAudioData.length - 20;
      while (temporaryAudioDataIndex < this.temporaryAudioData.length) {
        this.temporaryAudioData[this.temporaryAudioDataSamples++] =
          this.temporaryAudioData[temporaryAudioDataIndex++];
      }
      while (audioDataIndex < audioData.length) {
        this.temporaryAudioData[this.temporaryAudioDataSamples++] =
          audioData[audioDataIndex++];
      }
    }
    this.stateSetEnd();
  };

  // 録音の開始
  public resume = (): void => {
    console.log("== resume (0)", this.state);
    if (this.state !== -1 && this.state !== 0) {
      console.log(
        "ERROR: can't start recording (invalid state: " + this.state + ")"
      );
      return;
    }
    if (this.resumeStarted) this.resumeStarted();
    if (!window.AudioContext) {
      console.log(
        "ERROR: can't start recording (Unsupported AudioContext class)"
      );
      if (this.pauseEnded)
        this.pauseEnded(
          { code: 2, message: "Unsupported AudioContext class" },
          this.waveFile
        );
      return;
    }
    if (!navigator.mediaDevices) {
      console.log(
        "ERROR: can't start recording (Unsupported MediaDevices class)"
      );
      if (this.pauseEnded)
        this.pauseEnded(
          { code: 2, message: "Unsupported MediaDevices class" },
          this.waveFile
        );
      return;
    }

    if (this.state === -1) {
      // 各種変数の初期化
      this.initialize();
      this.state = 0;
    }
    if (!this.audioContext || !this.audioProcessor) return;
    if (this.isDownSampling) {
      console.log(this.audioContext);
      if (this.audioContext.sampleRate === 48000) {
        this.audioSamplesPerSec = 16000;
        this.audioDecimatationFactor = 3;
      } else if (this.audioContext.sampleRate === 44100) {
        this.audioSamplesPerSec = 22050;
        this.audioDecimatationFactor = 2;
      } else if (this.audioContext.sampleRate === 22050) {
        this.audioSamplesPerSec = 22050;
        this.audioDecimatationFactor = 1;
      } else if (this.audioContext.sampleRate === 16000) {
        this.audioSamplesPerSec = 16000;
        this.audioDecimatationFactor = 1;
      } else {
        this.audioSamplesPerSec = 0;
        this.audioDecimatationFactor = 0;
      }
    } else {
      this.audioSamplesPerSec = this.audioContext.sampleRate;
      this.audioDecimatationFactor = 1;
    }
    if (this.audioSamplesPerSec === 0) {
      console.log(
        "ERROR: can't start recording (Unsupported sample rate: " +
          this.audioContext.sampleRate +
          "Hz)"
      );
      const reason = {
        code: 2,
        message: `Unsupported sample rate: ${this.audioContext.sampleRate} Hz`,
      };
      if (this.pauseEnded) this.pauseEnded(reason, this.waveFile);
      return;
    }
    this.state = 1;
    if (this.audioDecimatationFactor > 1 && this.temporaryAudioData) {
      for (let i = 0; i <= 20; i++) {
        this.temporaryAudioData[i] = 0.0;
      }
      this.temporaryAudioDataSamples = 20;
    }
    if (!this.recorded) {
      this.waveData = [];
      this.waveDataBytes = 0;
      this.waveData.push(new ArrayBuffer(44));
      this.waveDataBytes += 44;
    }
    this.waveFile = undefined;
    console.log("== audioDecimatationFactor", this.audioDecimatationFactor);
    if (this.audioDecimatationFactor > 1) {
      if (this.recorded) {
        this.audioProcessor.onaudioprocess = this.downSamplingRecorded;
      } else {
        this.audioProcessor.onaudioprocess = this.downSampling;
      }
    } else {
      if (this.recorded) {
        this.audioProcessor.onaudioprocess = this.onRecorded;
      } else {
        this.audioProcessor.onaudioprocess = this.onAudioProcess;
      }
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((audioStream) => {
        if (!this.audioContext || !this.audioProcessor) return;
        if (this.state === 3) {
          this.state = 4;
          this.stopTracks();
          if (this.audioDecimatationFactor > 1) {
            console.log(
              "INFO: cancelled recording: " +
                this.audioContext.sampleRate +
                "Hz -> " +
                this.audioSamplesPerSec +
                "Hz (" +
                this.audioProcessor.bufferSize +
                " samples/buffer)"
            );
          } else {
            console.log(
              "INFO: cancelled recording: " +
                this.audioSamplesPerSec +
                "Hz (" +
                this.audioProcessor.bufferSize +
                " samples/buffer)"
            );
          }
          return;
        }
        this.state = 2;
        this.audioStream = audioStream;
        this.audioProvider =
          this.audioContext.createMediaStreamSource(audioStream);
        this.audioProvider.connect(this.audioProcessor);
        this.audioProcessor.connect(this.audioContext.destination);
        console.log("== connect", this.audioProcessor);
        if (this.audioDecimatationFactor > 1) {
          console.log(
            "INFO: started recording: " +
              this.audioContext.sampleRate +
              "Hz -> " +
              this.audioSamplesPerSec +
              "Hz (" +
              this.audioProcessor.bufferSize +
              " samples/buffer)"
          );
        } else {
          console.log(
            "INFO: started recording: " +
              this.audioSamplesPerSec +
              "Hz (" +
              this.audioProcessor.bufferSize +
              " samples/buffer)"
          );
        }
        if (this.resumeEnded) this.resumeEnded(this.audioSamplesPerSec);
      })
      .catch((error) => {
        this.state = 0;
        console.log("ERROR: can't start recording (" + error.message + ")");
        if (this.pauseEnded)
          this.pauseEnded({ code: 2, message: error.message }, this.waveFile);
      });
    return;
  };
  private stopTracks = () => {
    console.log("== stopTracks");
    if (!this.audioStream) return;
    const tracks = this.audioStream.getTracks();
    for (let i = 0; i < tracks.length; i++) {
      tracks[i].stop();
    }
    this.state = 0;
    if (this.waveData.length > 0) {
      const waveData = new DataView(this.waveData[0]);
      waveData.setUint8(0, 0x52); // 'R'
      waveData.setUint8(1, 0x49); // 'I'
      waveData.setUint8(2, 0x46); // 'F'
      waveData.setUint8(3, 0x46); // 'F'
      waveData.setUint32(4, this.waveDataBytes - 8, true);
      waveData.setUint8(8, 0x57); // 'W'
      waveData.setUint8(9, 0x41); // 'A'
      waveData.setUint8(10, 0x56); // 'V'
      waveData.setUint8(11, 0x45); // 'E'
      waveData.setUint8(12, 0x66); // 'f'
      waveData.setUint8(13, 0x6d); // 'm'
      waveData.setUint8(14, 0x74); // 't'
      waveData.setUint8(15, 0x20); // ' '
      waveData.setUint32(16, 16, true);
      waveData.setUint16(20, 1, true); // formatTag
      waveData.setUint16(22, 1, true); // channels
      waveData.setUint32(24, this.audioSamplesPerSec, true); // samplesPerSec
      waveData.setUint32(28, this.audioSamplesPerSec * 2 * 1, true); // bytesPseSec
      waveData.setUint16(32, 2 * 1, true); // bytesPerSample
      waveData.setUint16(34, 16, true); // bitsPerSample
      waveData.setUint8(36, 0x64); // 'd'
      waveData.setUint8(37, 0x61); // 'a'
      waveData.setUint8(38, 0x74); // 't'
      waveData.setUint8(39, 0x61); // 'a'
      waveData.setUint32(40, this.waveDataBytes - 44, true);
      this.waveFile = new Blob(this.waveData, { type: "audio/wav" });
      //this.waveFile.samplesPerSec = this.audioSamplesPerSec;
      //this.waveFile.samples = (this.waveDataBytes - 44) / (2 * 1);
      this.waveData = [];
      this.waveDataBytes = 0;
    }
    if (this.pauseEnded)
      this.pauseEnded({ code: 0, message: "stopTracks" }, this.waveFile);
  };

  // 録音の停止
  public pause = (): void => {
    console.log("== pause (2)", this.state);
    if (this.state !== 2) {
      console.log(
        "ERROR: can't stop recording (invalid state: " + this.state + ")"
      );
      return;
    }
    this.state = 3;
    if (this.pauseStarted) this.pauseStarted();
  };

  // 録音中かどうかの取得
  public isActive = (): boolean => {
    return this.state === 2;
  };
}
