
import Vue from "vue";

export default Vue.extend({
  name: "RecommendedEnvironment",
  methods: {
    onClose(): void {
      this.$emit("onClose");
    },
  },
});
