import { render, staticRenderFns } from "./Fukidasi.vue?vue&type=template&id=28cedacf&scoped=true&"
import script from "./Fukidasi.vue?vue&type=script&lang=ts&"
export * from "./Fukidasi.vue?vue&type=script&lang=ts&"
import style0 from "./Fukidasi.vue?vue&type=style&index=0&id=28cedacf&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28cedacf",
  null
  
)

export default component.exports